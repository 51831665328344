<template>
  <div class="sidemenu">
    <div class="d-flex align-center sidemenutitle" :style="{marginRight : distance + 'px'}">
      <div class="sidemenuimg" >
        <slot name="image" ></slot>
      </div>

      <h3 class="contanttitle primary--text ma-0 mr-4 mr-lg-5 font-weight-regular"><slot name="title" ></slot></h3>
    </div>


    <div>
    <slot name="contant"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  props : {
    distance : null,
  },
  data() {
    return {
      //
    }
  },
};
</script>


<style>

.sidemenu{
  background-color: #fcfcfc;
  padding: 23px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
}

@media only screen and (max-width : 968px) {
  .sidemenu{
    padding: 18px;
  }
}

.sidemenuimg{
  height: 60px;
  min-width: 60px;
  width: 60px;
  background-color: #570654;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidemenutitle{
  margin-top: 3.7px;
  margin-bottom: 46px;
  margin-right: 3.38px;
}

.contanttitle{
  font-size: 18px;
  user-select: none;
  max-width: 170px;
}

@media only screen and (min-width: 375px) {

  .contanttitle{
    font-size: 18px;
    max-width: 100%;
  }

}

</style>
