<template>
  <div class="breadcrumbs d-flex align-center">
    <v-container class="pa-0">
      <span class="ma-0" style="font-size: 14px"><slot></slot></span>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      //
    }
  },
};
</script>

<style>
.breadcrumbs{
  background-color: #570654;
  height: 54px;
  color: #ffffff;
  user-select: none;
}
</style>
