<template>
  <div style="min-height: 500px">
    <BreadCrumbs>خانه > نمایشگاه مجازی</BreadCrumbs>
    <transition enter-active-class="animate__animated animate__fadeIn"
                appear>
      <div>
        <v-container class="px-0 mt-10">

          <ContantBlock v-if="txt" class="mb-8" >
            <img slot="image" src="../assets/image/Public Icons/Side icons/icon5.png"/>
            <span slot="title" >نمایشگاه مجازی</span>
            <div slot="contant" class="pb-6">

              <div v-html="txt">

              </div>

            </div>
          </ContantBlock>

        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ContantBlock from "@/components/ContantBlock";
import axios from "axios";

export default {
  name: 'App',

  data() {
    return {
      txt : null,
    }
  },
  metaInfo() {
    return {
      title:'نمایشگاه مجازی',
      link: [{rel: 'canonical', href: this.$store.state.url+'/ex'}]
    }
  },
  created() {
    axios.get(this.$store.state.api + 'vexhibition').then(response=>{
      this.txt = response.data.data;
    });
  },
  components : {
    BreadCrumbs,
    ContantBlock,
  },
};
</script>
